import React from 'react';
import Home from "../pages/Home"
import {
    BrowserRouter,
    Routes,
    Route, createBrowserRouter
} from 'react-router-dom'
import GrantList from "../pages/Services/Authorize/Grant/list";
import GrantGroup from "../pages/Services/Authorize/Grant/group";
import SSO from "../pages/SSO";
import Applications from "../pages/Services/Authorize/Developer/AppsList"
import LogisticsSystemAccount from "../pages/Services/Saas/Product/4PL/Account";
import ItemDetail from "../pages/Self/Order/ItemDetail";
import Recharge from "../pages/Self/Order/Recharge";
import Warehouse from "../pages/Services/Saas/Product/Warehouse/Warehouse";
import Marketplace from "../pages/Services/Marletplace/Marketplace";
import ClientList from "../pages/Services/Authorize/Developer/ClientList";
import MiniPram from "../pages/Services/Marletplace/Minipram";
import Merchant from "../pages/Self/corp/Merchant";
import Wxa from "../pages/Services/Saas/Deposit/Miniprogram";
import Authorizes from '../pages/Services/Authorize/Developer/Authorizes';
import Users from '../pages/Services/Authorize/IAM/Users';
import Domains from '../pages/Services/Network/Inp/Domains'
import Corpus from '../pages/Services/Translate/Corpus'
import WorkflowManage from '../pages/Services/Workflow/Workflow'
import Job from '../pages/Services/Calc/Scheduler/Job'
import Registry from '../pages/Services/Calc/Scheduler/Registry'
import ExecuteGroup from '../pages/Services/Calc/Scheduler/ExecuteGroup'
import CorpusGroup from '../pages/Services/Translate/CorpusGroup'
import PortsForwarding from '../pages/Services/Network/Inp/PortsForwarding'
import Client from '../pages/Services/Network/Inp/Client'
import Saas from '../pages/Services/Saas/Portal/Saas'
import SaasServices from '../pages/Services/Saas/Portal/SaasServices'
import WorkflowNodes from '../pages/Services/Workflow/Nodes'
import WorkflowForms from '../pages/Services/Workflow/Forms'
import SmsHome from '../pages/Services/Comm/Resource/Sms/SmsHome'
import SignGraph from '../pages/Services/Comm/Resource/Sms/SignGraph'
import SendTemplate from '../pages/Services/Comm/Resource/Sms/SendTemplate'
import SmsApp from '../pages/Services/Comm/Resource/Sms/SmsApp'
import SendSMSMessage from '../pages/Services/Comm/Resource/Sms/SendSMSMessage'
import SmsRecord from '../pages/Services/Comm/Resource/Sms/SmsRecord'
import SesDomains from '../pages/Services/Comm/Resource/Ses/SesDomains'
import SesAccounts from '../pages/Services/Comm/Resource/Ses/SESAccounts'
import SesRecords from '../pages/Services/Comm/Resource/Ses/SesRecords'
import Endpoints from '../pages/Services/Comm/Resource/Storages/Endpoints'
import Buckets from '../pages/Services/Comm/Resource/Storages/Buckets'
import Invoice from '../pages/Self/Finance/Invs'
import Orders from '../pages/Self/Order/Orders'
import Channels from '../pages/Services/Comm/Push/Channels'
import Consumer from '../pages/Services/Comm/Push/Consumer'
import Record from '../pages/Services/Comm/Push/Record'
import DatabaseBackup from "../pages/Services/Comm/data/Backup";
import CrawlerEngine from '../pages/Services/Bigdata/crawler/CrawlerEngine'
import CrawlerRule from '../pages/Services/Bigdata/crawler/CrawlerRule'
import CrawlerObjects from '../pages/Services/Bigdata/crawler/CrawlerObjects'
import CrawlerModel from '../pages/Services/Bigdata/crawler/CrawlerModel'
import CrawlerSnapshot from '../pages/Services/Bigdata/crawler/CrawlerSnapshot'
import CrawlerSubscribeSense from '../pages/Services/Bigdata/crawler/CrawlerSubscribeSense'
import CrawlerSubscribe from '../pages/Services/Bigdata/crawler/CrawlerSubscribe'
import CrawlerModelMapping from '../pages/Services/Bigdata/crawler/CrawlerModelMapping'
import EIamProduct from '../pages/Services/Authorize/IAM/eiam/EIamProduct'
import EIamGroup from '../pages/Services/Authorize/IAM/eiam/EIamGroup'
import EIamEndpoint from '../pages/Services/Authorize/IAM/eiam/EIamEndpoint'
import XlsGenerateTask from '../pages/Services/Generate/XlsGenerateTask'

const routers = [
    {
        path: '/',
        element: <Home />,
        exact: true
    },
    {
        path: '/user',
        exact: false,
        children: [
            {
                path: '/user/manage',
                element: <Users />,
                exact:true
            },
            {
                path: '/user/finance',
                exact:false,
                children: [
                    {
                        path: '/user/finance/bills',
                        element: <Invoice />,
                    },
                    {
                        path: '/user/finance/invoice',
                        element: <Orders />,
                    },
                    {
                        path: '/user/finance/recharge',
                        element: <Recharge />,
                    }
                ]
            },
            {
                path: '/user/eiam',
                exact:false,
                children: [
                    {
                        path: '/user/eiam/product',
                        element: <EIamProduct />,
                    },
                    {
                        path: '/user/eiam/group',
                        element: <EIamGroup />,
                    },
                    {
                        path: '/user/eiam/endpoint',
                        element: <EIamEndpoint />,
                    }
                ]
            }
        ]
    },
    {
        path: '/services',
        exact: false,
        children: [
            {
                path: '/services/saas',
                exact:false,
                children: [
                    {
                        path: '/services/saas/home',
                        element: <Saas />,
                    },
                    {
                        path: '/services/saas/list',
                        element: <SaasServices />,
                    }
                ]
            }
        ]
    },
    {
        path: '/corp',
        exact: false,
        children: [
            {
                path: '/corp/info',
                element: <Merchant />,
                exact:true
            }
        ]
    },
    {
        path: '/network',
        exact: false,
        children: [
            {
                path: '/network/inp',
                exact:false,
                children: [
                    {
                        path: '/network/inp/domain',
                        element: <Domains />,
                        exact:false,
                    },
                    {
                        path: '/network/inp/port',
                        element: <PortsForwarding />,
                    },
                    {
                        path: '/network/inp/client',
                        element: <Client />,
                    }
                ]
            }
        ]
    },
    {
        path: '/files',
        exact: false,
        children: [
            {
                path: '/files/generate',
                exact:false,
                children: [
                    {
                        path: '/files/generate/task',
                        element: <XlsGenerateTask />,
                    }
                ]
            }
        ]
    },
    {
        path: '/calc',
        
        exact: false,
        children: [
            {
                path: '/calc/scheduler',
                exact:false,
                children: [
                    {
                        path: '/calc/scheduler/home',
                        element: <Job />,
                    },
                    {
                        path: '/calc/scheduler/registry',
                        element: <Registry />,
                    },
                    {
                        path: '/calc/scheduler/execute_group',
                        element: <ExecuteGroup />,
                    }
                ]
            },
            {
                path: '/calc/backup',
                exact:false,
                children: [
                    {
                        path: '/calc/backup/database',
                        element: <DatabaseBackup />
                    }
                ]
            }
        ]
    },
    {
        path: '/devops',
        exact: false,
        children: [
            {
                path: '/devops/sms',
                exact:false,
                children: [
                    {
                        path: '/devops/sms/home',
                        element: <SmsHome />,
                    },
                    {
                        path: '/devops/sms/sign',
                        element: <SignGraph />,
                    },
                    {
                        path: '/devops/sms/template',
                        element: <SendTemplate />,
                    },
                    {
                        path: '/devops/sms/application',
                        element: <SmsApp />,
                    },
                    {
                        path: '/devops/sms/send',
                        element: <SendSMSMessage />,
                    },
                    {
                        path: '/devops/sms/record',
                        element: <SmsRecord />,
                    }
                ]
            },
            {
                path: '/devops/ses',
                exact:false,
                children: [
                    {
                        path: '/devops/ses/domain',
                        element: <SesDomains />,
                    },
                    {
                        path: '/devops/ses/account',
                        element: <SesAccounts />,
                    },
                    {
                        path: '/devops/ses/records',
                        element: <SesRecords />,
                    },

                ]
            },
            {
                path: '/devops/storage',
                exact:false,
                children: [
                   {
                        path: '/devops/storage/endpoint',
                        element: <Endpoints />,
                    },
                   {
                        path: '/devops/storage/buckets',
                        element: <Buckets />,
                    },
                ]
            }
        ]
    },
    {
        path:  '/biz',
        exact: false,
        children: [
            {
                path: '/biz/flow',
                exact: false,
                children: [
                    {
                        path: '/biz/flow/admin',
                        element: <WorkflowManage />,
                    },
                    {
                        path: '/biz/flow/nodes',
                        element: <WorkflowNodes />,
                    },
                    {
                        path: '/biz/flow/forms',
                        element: <WorkflowForms />,
                    }
                ]
            }
        ]
    },
    {
        path: '/deposit',
        exact: false,
        children: [
            {
                path: '/deposit/wxa',
                element: <Wxa/>,
                exact:true
            }
        ]
    },
    {

        path: '/comm',
        exact: false,
        children: [
            {
                path: '/comm/msg-push',
                exact:false,
                children: [
                    {
                        path: '/comm/msg-push/channel',
                        element: <Channels />,
                    },
                    {
                        path: '/comm/msg-push/consumer',
                        element: <Consumer />,
                    },
                    {
                        path: '/comm/msg-push/record',
                        element: <Record />,
                    }
                ]
            }
        ]
    },
    {
        path: '/ai',
        children:[
            {
                path: '/ai/translate',
                children: [
                    {
                        path: '/ai/translate/list',
                        element: <Corpus />,
                        exact: false,
                    },
                    {
                        path: '/ai/translate/group',
                        element: <CorpusGroup />,
                    }
                ]
            }
        ]
    },
    {
        path: '/grant',
        exact: false,
        children: [
            {
                path: '/grant/list',
                element: <GrantList/>,
                exact:true
            },
            {
                path: '/grant/group',
                element:<GrantGroup />,
                exact:true
            }
        ]
    },
    {
        path:  "/applications",
        
        exact: false,
        children: [
            {
                path: '/applications/list',
                element: <Applications/>,
                exact:true
            },
            {
                path: '/applications/clients',
                element: <ClientList/>,
                exact:true
            },
            {
                path: '/applications/authorizes',
                element: <Authorizes/>,
                exact:true
            }
        ]
    },
    {
        path:  "/suitcase",
        exact: false,
        children: [
            {
                path: "/suitcase/4pl",
                exact: false,
                children: [
                    {
                        path: "/suitcase/4pl/list",
                        exact: false,
                        element: <LogisticsSystemAccount/>,
                    }
                ]
            },
            {
                path: "/suitcase/wms",
                exact: false,
                element: <Warehouse/>,
            },
            {
                path: "/suitcase/marketplace",
                exact: false,
                element: <Marketplace/>,
            },
            {
                path: "/suitcase/minipram",
                exact: false,
                element: <MiniPram/>,
            }
        ]
    },
    {
        path:  "/purchase",
        exact: false,
        children: [
            {
                path:  "/purchase/item",
                exact: false,
                children: [
                    {
                        path: '/purchase/item/:mpn',
                        element:<ItemDetail />,
                        exact:false
                    }
                ]
            },
            {
                path: '/purchase/recharge/:orderId',
                element: <Recharge />,
                exact:false
            },
        ]
    },
    {
        path:  "/Bigdata",
        exact: false,
        children: [
            {
                path:  "/Bigdata/crawler",
                exact: false,
                children: [
                    {
                        path: '/Bigdata/crawler/engine',
                        element:<CrawlerEngine />,
                    },
                    {
                        path: '/Bigdata/crawler/ruler',
                        element:<CrawlerRule />,
                    },
                    {
                        path: '/Bigdata/crawler/model',
                        element:<CrawlerModel />,
                    },
                    {
                        path: '/Bigdata/crawler/objects',
                        element:<CrawlerObjects />,
                    },
                    {
                        path: '/Bigdata/crawler/record',
                        element:<CrawlerSnapshot />,
                    },
                    {
                        path: '/Bigdata/crawler/sense',
                        element:<CrawlerSubscribeSense />,
                    },
                    {
                        path: '/Bigdata/crawler/subscribe',
                        element:<CrawlerSubscribe />,
                    },
                    {
                        path: '/Bigdata/crawler/model-mapping',
                        element:<CrawlerModelMapping />,
                    }

                ]
            }
        ]
    }
]

const RouterView = createBrowserRouter(routers)

export default RouterView

export class SSORouter extends React.Component {
    render() {
        return (
            <div id="main">
                <BrowserRouter>
                    <Routes>
                        <Route>
                            <Route path="/sso" element={<SSO />} exact={true}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </div>
        )
    }
}

