import React,{Component} from "react";
import HttpPost, {UploadProps} from "../../../utils/request";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import * as load from "../../../utils/loading";
import apis from "../../../utils/apis";

const {Option} = UI.Select
const {TextArea} = UI.Input

export default class WorkflowNodes extends React.Component{
    constructor() {
        super();
        this.state={
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            hanldeLoading: false
        }
    }
    componentDidMount() {
        this.GetTable(1)
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.GetTable()
        },200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        that.setState({
            tableLoad: true
        })
        Object.assign(para,that.state.searchKey)
        HttpPost('flow/nodeslist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "工作流节点",
                dataIndex: "name"
            },
            {
                title: "应用场景",
                key: "id",
                render: function (value, row, index) {
                    return <div>
                        {row.explain}
                    </div>
                }
            },
            {
                title: "工作流节点管理",
                key: "id",
                align: "right",
                fixed: 'right',
                render: function (value, row, index) {
                    return (
                        <span>
                           <UI.Button onClick={()=>{
                               that.setState({
                                   editCurr: JSON.parse(JSON.stringify(row)),
                                   editShow: true
                               })
                           }} type={"link"}>工作流节点编辑</UI.Button>
                       </span>

                    )
                }
            }
        ]
        return (
            <div>
                <PageHeader title="工作流节点管理" subTitle="审批流程，生产工艺，程序运行流程" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true,
                            hanldeLoading: false
                        })
                    }}>新增工作流节点</UI.Button>
                ]}/>
                <div style={{marginTop:'22px'}} id="hot-top-toolbar">
                    <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                        <UI.Form.Item label={"工作流节点名称"} name={"name__contains"}>
                            <UI.Input placeholder={"请输工作流节点名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"} htmlType={"submit"}>工作流节点搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.getTablePage}}/>
                </div>
                <UI.Modal visible={that.state.editShow} title={'工作节点管理'} onCancel={()=>{
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        hanldeLoading: false
                    })
                }} footer={null} destroyOnClose>
                    <UI.Form onFinish={(params => {
                        that.setState({
                            hanldeLoading: true
                        })
                        if (that.state.editCurr.id) {
                            params.id = that.state.editCurr.id
                        }
                        apis.WorkflowNodeAdmin(params).then(resp => {
                            that.setState({
                                hanldeLoading: false
                            })
                            if (resp.data.code === 200 && resp.data.data.code === 200) {
                                UI.message.success("提交工作流节点信息成功")
                                that.GetTable(that.state.editCurr.id ? that.state.tablePage.currPage : 1)
                                that.setState({
                                    hanldeLoading: false,
                                    editShow: false,
                                    editCurr: {}
                                })
                            } else {
                                UI.message.error(resp.data.msg)
                            }
                        })
                    })} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item label={"工作节点流名称"} name={"name"} rules={[{required:true,message:'请输入工作流节点名称名称'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder={"请输入工作流节点名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"工作流节点介绍"} name={"explain"} help="用途，应用场景说明" rules={[{required:true,message:'请输入工作流工作流介绍'}]} initialValue={this.state.editCurr.explain}>
                            <TextArea rows={8} placeholder={"请输入工作流工作流节点介绍"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} loading={that.state.hanldeLoading} type={"primary"} block>提交工作流节点信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        );
    }
}