import React,{Component} from "react";
import HttpPost, {UploadProps} from "../../../utils/request";
import * as UI from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import * as load from "../../../utils/loading";
import apis from "../../../utils/apis";

const {Option} = UI.Select
const {TextArea} = UI.Input

export default class WorkflowForms extends React.Component{
    constructor() {
        super();
        this.state={
            tableData: [],
            tablePage: {total: 0,currPage:1},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            hanldeLoading: false,
            fieldEditCurr: {},
            fieldList: [],
            fieldEditShow: false,
            fieldHandleLoding: false,
            fieldTableLoading: false,
            fieldTpl: {label: '', prop: '', form_field_type: 'text', required: true, help: '', placeholder: '', rows: 1, sort: 1, state: true},
            fieldShow: false,
            fieldCurr: {},
            defaultFormFieldType: "text",
            currFrom: {}
        }
    }
    componentDidMount() {
        this.GetTable(1)
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    MakeSearch=(params)=>{
        console.log(params)
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.GetTable()
        },200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    GetTable=(pageNumber=1,pageSize=20)=>{
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = pageSize;
        that.setState({
            tableLoad: true
        })
        Object.assign(para,that.state.searchKey)
        HttpPost('flow/formslist',para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                tablePage: curr
            })
        })
    }
    editFieldRef = React.createRef()

    /**
     * 工作流表单详情
     * @param {*} formId 
     */
    getForm = (formId) => {
        var that = this
        apis.getWorkflowDetailInfo(formId).then(ret => {
            that.setState({
                currFrom: ret.data.data,
                fieldList: ret.data.data.fields
            })
        })
    }
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "工作流表单",
                dataIndex: "name"
            },
            {
                title: "应用场景",
                key: "id",
                render: function (value, row, index) {
                    return <div>
                        {row.explain}
                    </div>
                }
            },
            {
                title: "工作流表单管理",
                key: "id",
                align: "right",
                fixed: 'right',
                render: function (value, row, index) {
                    return (
                        <span>
                           <UI.Button onClick={()=>{
                               that.setState({
                                   editCurr: JSON.parse(JSON.stringify(row)),
                                   editShow: true
                               })
                           }} type={"link"}>表单编辑</UI.Button>
                           <UI.Button type={"link"} onClick = {() => {
                                that.getForm(row.id)
                                that.setState({
                                    fieldEditShow: true,
                                    fieldHandleLoding: false
                                })
                           }}>字段设计</UI.Button>
                       </span>

                    )
                }
            }
        ]

        var fieldCol = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "字段名称",
                dataIndex: "label"
            },
            {
                title: "字段",
                dataIndex: "prop"
            },
            {
                title: "表单字段类型",
                dataIndex: "form_field_type"
            },
            {
                title: "必须",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <UI.Button shape="circle" type="primary" danger>{row.required ? "是" : "否"}</UI.Button>
                    )
                }
            },
            {
                title: "填写提示",
                key: "id",
                render: function (value, row, index) {
                    return (
                        row.place_holder ? row.place_holder : "无"
                    )
                }
            },
            {
                title: "填写说明",
                key: "id",
                render: function (value, row, index) {
                    return (
                        row.help ? row.help : "无"
                    )
                }
            },
            {
                title: "行数",
                key: "id",
                render: function (value, row, index) {
                    return (
                        row.rows ? row.rows : "无"
                    )
                }
            },
            {
                title: "最小值",
                key: "id",
                render: function (value, row, index) {
                    return (
                        row.min_value ? row.min_value : "无"
                    )
                }
            },
            {
                title: "最大值",
                key: "id",
                render: function (value, row, index) {
                    return (
                        row.max_value ? row.max_value : "无"
                    )
                }
            },
            {
                title: "排序",
                dataIndex: "sort"
            },
            {
                title: "字段管理",
                key: "id",
                align: "right",
                fixed: 'right',
                render: function (value, row, index) {
                    return (
                        <div>
                            <UI.Button onClick={()=>{
                                load.Loading()
                                apis.WorlflowFormFieldList(row.id).then(fieldresponse => {
                                    load.HasLoaded()
                                    that.setState({
                                        fieldList: fieldresponse.data.data,
                                        fieldCurr: JSON.parse(JSON.stringify(row)),
                                        fieldShow: true,
                                        fieldHandleLoding: false
                                    })
                                })
                           }} type={"link"}>字段编辑</UI.Button>
                        </div>
                    )
                }
            }
        ]
        return (
            <div>
                <PageHeader title="工作流表单管理" subTitle="审批流程，生产工艺，程序运行流程" extra={[
                    <UI.Button  key="1" type="primary" shape="round" onClick={()=>{
                        that.setState({
                            editCurr: {},
                            editShow: true,
                            hanldeLoading: false
                        })
                    }}>新增工作流表单</UI.Button>
                ]}/>
                <div style={{marginTop:'22px'}} id="hot-top-toolbar">
                    <UI.Form onFinish={that.MakeSearch} layout={"inline"}>
                        <UI.Form.Item label={"工作流表单名称"} name={"name__contains"}>
                            <UI.Input placeholder={"请输工作流表单名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button type={"primary"} htmlType={"submit"}>工作流表单搜索</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </div>
                <div style={{marginTop:'22px'}}>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{total:this.state.tablePage.total,defaultPageSize:20,defaultCurrent:this.state.tablePage.currPage,onChange:this.getTablePage}}/>
                </div>
                <UI.Modal visible={that.state.editShow} title={'工作表单管理'} onCancel={()=>{
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        hanldeLoading: false
                    })
                }} footer={null} destroyOnClose>
                    <UI.Form onFinish={(params => {
                        that.setState({
                            hanldeLoading: true
                        })
                        if (that.state.editCurr.id) {
                            params.id = that.state.editCurr.id
                        }
                        apis.WorkflowFormAdmin(params).then(resp => {
                            that.setState({
                                hanldeLoading: false
                            })
                            if (resp.data.code === 200 && resp.data.data.code === 200) {
                                UI.message.success("提交工作表单信息成功")
                                that.GetTable(that.state.editCurr.id ? that.state.tablePage.currPage : 1)
                                that.setState({
                                    hanldeLoading: false,
                                    editShow: false,
                                    editCurr: {}
                                })
                            } else {
                                UI.message.error(resp.data.msg)
                            }
                        })
                    })} labelCol={{span:24}} labelAlign={"left"}>
                        <UI.Form.Item label={"工作表单流名称"} name={"name"} rules={[{required:true,message:'请输入工作流表单名称名称'}]} initialValue={this.state.editCurr.name}>
                            <UI.Input placeholder={"请输入工作流表单名称"}/>
                        </UI.Form.Item>
                        <UI.Form.Item label={"工作流表单介绍"} name={"explain"} help="用途，应用场景说明" rules={[{required:true,message:'请输入工作流工作流介绍'}]} initialValue={this.state.editCurr.explain}>
                            <TextArea rows={8} placeholder={"请输入工作流工作流表单介绍"}/>
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} loading={that.state.hanldeLoading} type={"primary"} block>提交工作流表单信息</UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
                <UI.Modal width={"80vw"} bodyStyle={{minHeight:"70vh"}} visible={that.state.fieldEditShow} onCancel={()=>{
                    that.setState({
                        fieldEditCurr: {},
                        fieldEditShow: false,
                        fieldHandleLoding: false
                    })
                }} footer={null} destroyOnClose>
                    <div style={{justifyContent:"space-between",display:"flex",marginTop:"36px"}}>
                        <h2 style={{fontWeight:"bold"}}>表单字段管理</h2>
                        <UI.Button type="primary" shape="round" onClick={() => {
                            that.setState({
                                fieldShow: true,
                                fieldCurr: {},
                                fieldHandleLoding: false
                            })
                        }}>+ 新增字段</UI.Button>
                    </div>
                    <UI.Divider/>
                    <UI.Table
                        rowKey="id"
                        dataSource={this.state.fieldList}
                        columns={fieldCol}
                        loading={this.state.fieldTableLoading} pagination={null}/>
                </UI.Modal>
                <UI.Modal visible={that.state.fieldShow} footer={null} onCancel={() => {
                    that.setState({
                        fieldShow: false,
                        fieldCurr: {},
                        fieldHandleLoding: false
                    })
                }} destroyOnClose>
                    <h2 style={{fontWeight:"bold"}}>字段属性管理</h2>
                    <UI.Divider/>
                    <UI.Form ref={that.editFieldRef} onFinish={(params) => {
                        that.setState({
                            fieldHandleLoding: true
                        })
                        console.log(that.state.currFrom)
                        if (that.state.fieldCurr.id) {
                            params.id = that.state.fieldCurr.id
                        }
                        params.form_id = that.state.currFrom.id
                        apis.WorkflowFromFieldAdmin(params).then(resp => {
                            that.setState({
                                fieldHandleLoding: false
                            })
                            if (resp.data.code === 200 && resp.data.data.code === 200) {
                                UI.message.success("表单字段信息提交成功")
                                apis.WorlflowFormFieldList(that.fieldEditCurr.id).then(fieldresponse => {
                                    load.HasLoaded()
                                    that.setState({
                                        fieldList: fieldresponse.data.data
                                    })
                                })
                                that.setState({
                                    fieldShow: false,
                                    fieldCurr: {}
                                })
                                that.getForm(that.state.currFrom.id)
                            } else {
                                UI.message.error(resp.data.msg)
                            }
                        })
                    }} labelAlign="left" labelCol={{ span: 24 }}>
                        <UI.Form.Item label="字段名称" name={"label"}
                                      rules={[{ required: true, message: '请输入字段名称' }]}
                                      initialValue={this.state.fieldCurr.label}>
                            <UI.Input placeholder="请输入字段名称" />
                        </UI.Form.Item>
                        <UI.Form.Item label="字段可用" name={"state"} rules={[{ required: true, message: '字段可用' }]}
                                      initialValue={this.state.fieldCurr.state}>
                            <UI.Radio.Group>
                                <UI.Radio value={true}>是</UI.Radio>
                                <UI.Radio value={false}>否</UI.Radio>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label="多选" name={"state"} rules={[{ required: false, message: '字段多选' }]}
                                      initialValue={this.state.fieldCurr.state} help={"在文件上传以及下拉选择时使用"}>
                            <UI.Radio.Group>
                                <UI.Radio value={true}>是</UI.Radio>
                                <UI.Radio value={false}>否</UI.Radio>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label="排序" name={"sort"} rules={[{ required: true, message: '请输入字段排序' }]}
                                      initialValue={this.state.fieldCurr.sort ? this.state.fieldCurr.sort : 1}>
                            <UI.InputNumber style={{ width: "100%" }} precision={0} min={1} step={1}
                                            placeholder="请输入字段排序" />
                        </UI.Form.Item>
                        <UI.Form.Item label="字段" name={"prop"} rules={[{ required: true, message: '请输入字段' }]}
                                      initialValue={this.state.fieldCurr.prop}>
                            <UI.Input placeholder="请输入字段" />
                        </UI.Form.Item>
                        <UI.Form.Item label="表单字段类型" name={"form_field_type"}
                                      rules={[{ required: true, message: '表单字段类型' }]}
                                      initialValue={this.state.fieldCurr.form_field_type}>
                            <UI.Select placeholder="请选择表单字段类型" onSelect={(formFieldType) => {
                                that.setState({
                                    defaultFormFieldType: formFieldType
                                })
                            }}>
                                <Option value="text">文本文档</Option>
                                <Option value="textarea">文本域</Option>
                                <Option value="image">图片</Option>
                                <Option value="boolean">布尔值</Option>
                                <Option value="video">视频</Option>
                                <Option value="number">数字</Option>
                                <Option value="file">文件</Option>
                                <Option value="select">下拉选项</Option>
                                <Option value="radio">单选项</Option>
                                <Option value="checkedbox">多选项</Option>
                                <Option value="date">日期</Option>
                                <Option value="datetime">时间</Option>
                                <Option value="html">富文本</Option>
                            </UI.Select>
                        </UI.Form.Item>
                        {that.state.defaultFormFieldType === "number" &&
                          <span>
                            <UI.Form.Item label="最小值" name={"min_value"}
                                          rules={[{ required: true, message: '最小值' }]}
                                          initialValue={this.state.fieldCurr.min_value}>
                                <UI.InputNumber style={{ width: "100%" }} placeholder="最小值" />
                            </UI.Form.Item>
                            <UI.Form.Item label="最小值" name={"max_value"}
                                          rules={[{ required: true, message: '最小值' }]}
                                          initialValue={this.state.fieldCurr.max_value}>
                                <UI.InputNumber style={{ width: "100%" }} placeholder="最大值" />
                            </UI.Form.Item>
                            <UI.Form.Item label="保留小数位数" name={"precision"}
                                          rules={[{ required: true, message: '保留小数位数' }]}
                                          initialValue={this.state.fieldCurr.precision}>
                                <UI.InputNumber style={{ width: "100%" }} precision={0} min={1} step={0}
                                                placeholder="保留小数位数" />
                            </UI.Form.Item>
                        </span>
                        }
                        {that.state.defaultFormFieldType === "textarea" &&
                          <span>
                            <UI.Form.Item label="文本域行数" name={"rows"}
                                          rules={[{ required: true, message: '文本域行数' }]}
                                          initialValue={this.state.fieldCurr.rows}>
                                <UI.InputNumber style={{ width: "100%" }} precision={0} min={1} step={1}
                                                placeholder="文本域行数" />
                            </UI.Form.Item>
                        </span>
                        }
                        {
                          ["select", "radio", "checkedbox"].includes(that.state.defaultFormFieldType) &&
                          <UI.Form.Item label="可选择值" help="一行一个可选择值" name={"available_values"}
                                        rules={[{ required: true, message: '可选择值' }]}
                                        initialValue={this.state.fieldCurr.available_values}>
                              <TextArea rows={10} placeholder="可选择值" />
                          </UI.Form.Item>
                        }
                        <UI.Form.Item label="必须字段" name={"required"}
                                      rules={[{ required: true, message: '必须字段' }]}
                                      initialValue={this.state.fieldCurr.required}>
                            <UI.Radio.Group>
                                <UI.Radio value={true}>是</UI.Radio>
                                <UI.Radio value={false}>否</UI.Radio>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                        <UI.Form.Item label="填写提示" name={"place_holder"}
                                      rules={[{ required: true, message: '填写提示' }]}
                                      initialValue={this.state.fieldCurr.place_holder}>
                            <UI.Input placeholder="填写提示" />
                        </UI.Form.Item>
                        <UI.Form.Item label="填写说明 / 帮助" name={"help"}
                                      rules={[{ required: false, message: '填写说明 / 帮助' }]}
                                      initialValue={this.state.fieldCurr.help}>
                            <TextArea rows={5} placeholder="填写说明 / 帮助" />
                        </UI.Form.Item>
                        <UI.Form.Item>
                            <UI.Button htmlType={"submit"} loading={that.state.fieldHandleLoding} type={"primary"}
                                       block>提交字段参数信息
                            </UI.Button>
                        </UI.Form.Item>
                    </UI.Form>
                </UI.Modal>
            </div>
        );
    }
}