/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/12/30
 */
import React, { Component } from 'react'
import * as ui from 'antd'
import { BellOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import apis from '../../../utils/apis'
import dayjs from 'dayjs'
import { LoadingEnded, LoadingStart } from '../../../utils/loading'


const { TextArea } = ui.Input
const { Option } = ui.Select

export default class XlsGenerateTask extends Component {
  constructor() {
    super()
    this.state = {
      tableData: [],
      paging: { total: 0, pageNumber: 1, pageSize: 20 },
      tableLoad: false,
      searchKey: {},
      editShow: false,
      editCurr: {},
      submitting: false,
    }
  }

  componentDidMount() {
    this.tableDataSync(1)
  }

  /**
   * 发起搜索
   * @param params
   * @constructor
   */
  makeSearch = (params) => {
    this.setState({
      searchKey: params
    })
    var that = this
    setTimeout(function() {
      that.tableDataSync()
    }, 200)
  }
  /**
   * 签名列表
   * @param pageNumber
   Object.assign(para, that.state.keywords)
   * @param pageSize
   * @constructor
   */
  tableDataSync = (pageNumber = 1) => {
    let that = this
    let para = {}
    para.pageNumber = pageNumber
    para.pageSize = 20
    that.setState({
      tableLoad: true
    })
    Object.assign(para, that.state.searchKey)
    apis.fileCloudGenerateTaskList(para).then(function(resp) {
      that.setState({
        tableLoad: false
      })
      var curr = {
        total: resp.data.data.total,
        pageNumber: pageNumber
      }
      that.setState({
        tableData: resp.data.data.data,
        paging: curr
      })
    })
  }


  render() {
    var that = this

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        title: '任务名称',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>
              <h2>{row.name}</h2>
              <small>{row.remark}</small>
            </div>
          )
        }
      },
      {
        title: '验证代码',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{row.code ?? '无'}</div>
          )
        }
      },
      {
        title: '生成类型',
        key: 'id',
        render: function(value, row, index) {
          return row.generate_type
        }
      },
      {
        title: '文件格式',
        key: 'id',
        render: function(value, row, index) {
          return row.target_suffix
        }
      },
      {
        title: '创建时间',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'round'} type={'dashed'} danger>
              {dayjs(row.created_at).format("YYYY-MM-DD HH:mm:ss")}
            </ui.Button>
          )
        }
      },
      {
        title: '可用',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'round'} type={'circle'}>{row.state ? '是' : '否'}</ui.Button>
          )
        }
      },
      {
        title: '管理',
        key: 'id',
        fixed: 'right',
        align: 'center',
        render: function(value, row, index) {
          return (
            <span>
               <ui.Button onClick={() => {
                 LoadingStart()
                 apis.fileCloudGenerateTaskMeshWork(row.id,"generate").then(resp => {
                   LoadingEnded()
                   if (resp.data.code === 200) {
                     ui.message.success('推送成功')
                   } else {
                     ui.message.error(resp.data.msg)
                   }
                 })
               }} type={'link'}>重新生成</ui.Button>
              {row.result &&
                <span>
                  <ui.Button onClick={() => {
                    LoadingStart()
                    apis.fileCloudGenerateTaskMeshWork(row.id,"notify").then(resp => {
                      LoadingEnded()
                      if (resp.data.code === 200) {
                        ui.message.success('推送成功')
                      } else {
                        ui.message.error(resp.data.msg)
                      }
                    })
                  }} type={'link'}>手动推送</ui.Button>
                  <ui.Button onClick={() => {
                    var resultUrl = JSON.parse(row.result).url
                    var fileUrl = resultUrl.startsWith('http') ? resultUrl : `https:${resultUrl}`
                    const link = document.createElement('a')
                    link.href = fileUrl
                    link.download = `${row.name}.${row.target_suffix}`
                    link.target = '_blank'
                    link.click()
                  }} type={'link'}>文件下载</ui.Button>
                </span>
              }
            </span>
          )
        }
      }
    ]

    return (
      <div>
        <PageHeader title="文档生成任务" subTitle="文档生成任务查看，手动生成" />
        <ui.Card>
          <div style={{ marginTop: '22px' }}>
            <ui.Form onFinish={that.makeSearch} layout={'inline'}>
            <ui.Form.Item label={'任务名称'} name={'name__contains'}>
                <ui.Input placeholder={'任务名称'} />
              </ui.Form.Item>
              <ui.Form.Item>
                <ui.Button type={'primary'} htmlType={'submit'}>快速搜索</ui.Button>
              </ui.Form.Item>
            </ui.Form>
          </div>
        </ui.Card>

        <div style={{ marginTop: '22px' }}>
          <ui.Table
            rowKey="id"
            dataSource={this.state.tableData}
            columns={columns}
            loading={this.state.tableLoad}
            pagination={{
              total: this.state.paging.total,
              defaultPageSize: 20,
              defaultCurrent: this.state.paging.pageNumber,
              onChange: this.tableDataSync
            }} />
        </div>

        <ui.Modal open={that.state.editShow} title={'定时任务执行客户端名称'} onCancel={() => {
          that.setState({
            editCurr: {},
            editShow: false,
            submitting: false
          })
        }} footer={null} destroyOnClose>
          <ui.Form onFinish={(params => {
            that.setState({
              submitting: true
            })
            if (that.state.editCurr.id) {
              params.id = that.state.editCurr.id
            }
            apis.schedulerExecuteRegistry(params).then(resp => {
              that.setState({
                submitting: false
              })
              if (resp.data.code === 200 && resp.data.data.code === 200) {
                ui.message.success('提交信息成功')
                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                that.setState({
                  submitting: false,
                  editShow: false,
                  editCurr: {}
                })
              } else {
                ui.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
              }
            })
          })} labelCol={{ span: 24 }} labelAlign={'left'} initialValues={that.state.editCurr}>
            <ui.Form.Item label={'执行客户端名称'} name={'name'}
                          rules={[{ required: true, message: '执行客户端名称' }]}>
              <ui.Input placeholder={'执行客户端名称'} disabled={that.state.editCurr.id} />
            </ui.Form.Item>
            <ui.Form.Item label={'执行客户端名称'} name={'state'}
                          rules={[{ required: true, message: '执行客户端名称' }]}>
              <ui.Radio.Group>
                <ui.Radio value={true}>可用</ui.Radio>
                <ui.Radio value={false}>禁用</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            <ui.Form.Item label={'执行器'} name={'value'} help="地址样式:http://ip:8999"
                          rules={[{ required: true, message: '执行器' }]}>
              <ui.Input placeholder={'执行器'} />
            </ui.Form.Item>
            <ui.Form.Item>
              <ui.Button htmlType={'submit'} loading={that.state.submitting} type={'primary'} block>立即提交</ui.Button>
            </ui.Form.Item>
          </ui.Form>
        </ui.Modal>
      </div>
    )
  }
}