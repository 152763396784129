import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost,{UploadProps} from "../../../utils/request";
import * as load from "../../../utils/loading"

const {Search, TextArea}=UI.Input;

export default class Recharge extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            withSku: null,
            order: {},
            orderItems: [],
            payment: []
        }
    }
    componentDidMount() {
        this.GetOrderInformation()
        this.GetOrderItems()
        this.GetPayment()
    }
    GetPayment = ()=>{
        var that = this;
        HttpPost('system/gethottolpayment').then(function (res) {
            that.setState({
                payment:res.data.data
            })
        })
    }
    GetOrderItems = () =>{
        var that = this;
        var orderId = window.location.pathname.split('/').pop();
        var para = {};
        para.orderId = orderId;
        HttpPost("system/getorderitems",para).then(function (res) {
            that.setState({
                orderItems: res.data.data
            })
        })
    }

    getOrderId = () =>{

    }

    GetOrderInformation = () =>{
        var that = this;
        var orderId = window.location.pathname.split('/').pop();
        var para = {};
        para.orderId = orderId;
        HttpPost("system/getorderinformation",para).then(function (res) {
            that.setState({
                order: res.data.data
            })
        })
    }
    //担交订单结算
    Handle = (values)=>{
        var that = this
        values.orderId = window.location.pathname.split('/').pop();
        load.Loading()
        HttpPost("system/hottolrecharge",values).then(function (res) {
            load.HasLoaded()
            if (res.data.code===200){
                if (res.data.data.code ===200) {
                    if (res.data.data.action === "ended") {
                        UI.message.success("支付成功，系统将返回产品页面")
                        setTimeout(function () {
                            window.location.href = that.state.orderItems[0].sku.item.sys_url
                        },2000)
                    } else if (res.data.data.action === "URL"){
                        window.location.href = res.data.data.url
                    }
                } else {
                    UI.message.warning(res.data.data.msg)
                }
            } else {
                UI.message.error("发生系统错误，请稍后重试!")
            }
        })
    }
    tableConfig = [
        {
            title:'订单商品ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title:"商品",
            dataIndex: "sku",
            key: "sku.item.title",
            render: function(v,row,index){

                return (
                    <div>
                        <h3>{row.sku.item.title}</h3>
                        <small style={{fontSize: '0.8rem'}}><b>产品规格: </b>{Object.values(JSON.parse(row.sku.standard)).join(' / ')}</small>
                    </div>
                )
            }
        },
        {
            title:"采购数量",
            dataIndex: "qty",
            key: "qty"
        },
        {
            title:'采购单价',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: "采购总价",
            dataIndex: 'price',
            render: function (v,row,index) {
                return (
                    <span>{row.qty*row.price} {row.currency}</span>
                )
            }
        }
    ];
    render() {
        var that = this;
        if (Object.keys(this.state.order).length === 0) {
            return (
                <div style={{marginTop:"25vh",textAlign:"center"}}>
                    数据加载中，请稍后...
                </div>
            )
        }else {
            return (
                <div>
                    <PageHeader
                        title="订单结算"
                        onBack={()=>{
                            that.props.history.push("/")
                        }}
                        subTitle="请选择合适的支付方式与优惠项目对订单进行结算"/>
                        <div style={{width:"70vw", margin:"0 auto"}}>
                            <div style={{marginTop:"3.6rem"}}>
                                <h2><strong>订单号: </strong>{that.state.order.orderId}</h2>
                                <div style={{marginTop:"2.6rem"}}>
                                    <UI.Table
                                        rowKey="id"
                                        pagination={false}
                                        dataSource={this.state.orderItems}
                                        columns={that.tableConfig}/>
                                </div>
                            </div>
                            <div style={{marginTop:'3.6rem'}}>
                                <UI.Form onFinish={this.Handle} labelCol={{span:24}} labelAlign={"left"}>
                                    <UI.Form.Item label="支付方式" name="payment" rules={[{required:true,message:"请用合适的支付方式"}]}>
                                        <UI.Radio.Group name="payment">
                                            {that.state.payment.map(pay=>{
                                                return (
                                                    <UI.Radio key={pay.id} value={pay.id}>{pay.name}</UI.Radio>
                                                )
                                            })}
                                        </UI.Radio.Group>
                                    </UI.Form.Item>
                                    <UI.Form.Item style={{textAlign:'right'}}>
                                        <UI.Button type="primary" shape="round" htmlType="submit">立即结算</UI.Button>
                                    </UI.Form.Item>
                                </UI.Form>
                            </div>
                        </div>
                </div>
            )
        }
    }
}
