import React from "react";
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import HttpPost,{UploadProps} from "../../../utils/request";
import * as load from "../../../utils/loading"


const {Search, TextArea}=UI.Input
const { Option } = UI.Select

export class FormRadio extends React.Component{
    constructor(props) {
        super(props);
    }
    render() {
        return (
            this.props.vls.map(vl=>{
                return (
                    <UI.Radio key={vl} value={vl}>{vl}</UI.Radio>
                )
            })
        )
    }
}

export default class ItemDetail extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            item: {currency: 'CNY',standard_mapping: {}, item_type: {need_ref_client: false, app_domain: null}},
            defaultQty: 1,
            variants: [],
            price: 0,
            currVariant: {sale_price: 0},
            clients: []
        }
    }

    componentDidMount() {
        this.GetItemInformation()
        this.GetVariants()
        this.GetClients()
    }

    GetClients = () => {
        let para = {};
        para.pageNumber = 1;
        para.pageSize = 99999;
        var that = this;
        HttpPost('grant/clientlist',para).then(function (resp) {
            that.setState({
                clients: resp.data.data.data
            })
        })
    }
    GetVariants = () => {
        var that = this;
        var mpn = "4pl";
        var para = {};
        para.mpn = mpn;
        HttpPost("system/getvariants", para).then(function (res) {
            load.HasLoaded();
            let dd = res.data.data;
            that.setState({
                variants: dd,
                currVariant: dd[0]
            })
        })
    }
    GetVariant = (vls) => {
        var that = this;
        var varant = null;
        var checkStr = Object.values(vls).sort().toString();
        console.log(`原始数据：${checkStr}`)
        var data = that.state.variants;
        for (var i = 0; i < that.state.variants.length; i ++) {
            var curr = Object.values(JSON.parse(data[i].standard)).sort().toString()
            console.log(`验证数据:${curr}`)
            if (curr === checkStr) {
                varant = data[i];
                that.setState({
                    currVariant: varant
                })
                break;
            }
        }
        return varant;
    }
    GetItemInformation = () => {
        console.log(this.props)
        var that = this;
        var para = {};
        para.mpn = window.location.pathname.split('/').pop();
        HttpPost("system/getserviceinformation", para).then(function (res) {
            let dd = res.data.data;
            dd.standard_mapping = JSON.parse(dd.standard_mapping);
            that.setState({
                item: dd
            })
        })
    }
    SelectNewService = (event)=>{
        var varList = this.state.variants;
        var that = this;
        for (var i = 0; i < varList.length;i++){
            var curr = JSON.parse(varList[i].standard);
            if (curr[event.target.name] === event.target.value){
                that.setState({
                    currVariant: varList[i]
                })
            }
        }
        console.log(event)
    }
    Handle = (values) =>{
        var that = this;
        var para = {};
        Object.assign(para, values);
        var params = para;
        var para = {}
        Object.keys(JSON.parse(that.state.item.item_type.fields)).forEach(curr=>{
            para[curr]=values[curr]
        })
        var varaiant = this.GetVariant(para);
        if (that.state.item.item_type.app_domain) {
            params['property[domain]'] = that.state.item.item_type.app_domain
        }
        var property = {}
        Object.keys(params).forEach(key=>{
            if (key.indexOf('property')!==-1) {
                property[key.replace('property[','').replace(']','')]=params[key]
                delete params[key]
            }
        })
        params.property = property
        if (varaiant) {
            params.sku = varaiant.id;
            load.Loading();
            HttpPost("system/onekeypurchase", params).then(function (res) {
                load.HasLoaded();
                if (res.data.code === 200) {
                    if (res.data.data.code === 200) {
                        window.location.href = `/purchase/recharge/${res.data.data.orderId}`
                    } else {
                        UI.message.error("Hint,无法创建新的订单")
                    }
                } else {
                    UI.message.error("Hint,无法创建新的订单")
                }
            })
        } else {
            UI.message.error("不存在采购的商品或则采购商品已经下架")
        }
    }
    /**
     * 域名检查
     * @param domain
     * @param record
     * @constructor
     */
    DomainCheck = (rule,value,callback)=>{
        var that = this
        var domain = 'apptago.com'
        if (that.state.item.item_type.app_domain.indexOf('appify.cn')!==-1){
            domain = 'appify.cn'
        }
        var check = that.state.item.item_type.app_domain.replace(`.${domain}`,'')
        if (check.length > 0) {
            value = `${value}.${check}`
        }
        var para = {}
        para.domain = domain
        para.subdomain = value
        HttpPost("system/checkdomainexites",para).then(function (resp) {
            if (resp.data.code === 200) {
                if (resp.data.data.code === 200) {
                    callback()
                } else {
                    callback(resp.data.data.error)
                }
            } else {
                callback(resp.data.msg)
            }
        })
    }
    render() {
        var that = this
        const Client = () =>{
            if (that.state.item.item_type.need_ref_client) {
                return (
                    <UI.Form.Item label="关联终端" name='property[client]' rules={[{required:true,message:"请选择关联终端"}]}>
                        <UI.Select placeholder="请选择关联终端">
                            { that.state.clients.map(cl=>{
                                return (
                                    <Option value={cl.id} key={cl.id}>{cl.name}</Option>
                                )
                            })
                            }
                        </UI.Select>
                    </UI.Form.Item>
                )
            } else {
                return null
            }
        }
        const Domain = ()=>{
            if (that.state.item.item_type.app_domain) {
                return (
                    <UI.Form.Item label="域名设置" name='property[subdomain]'
                                  rules={[{required:true,message:"请选择关联终端"},{validator: that.DomainCheck}]}>
                        <UI.Input addonAfter={that.state.item.item_type.app_domain} placeholder="请配置您所期望的域名，使用根域名请输入@" />
                    </UI.Form.Item>
                )
            } else {
                return null
            }
        }
        const Choose = () =>{
            return (
                Object.keys(that.state.item.standard_mapping).map(curr => {
                    return (
                        <UI.Form.Item
                            key={curr}
                            name={curr}
                            rules={[{required:true,message:`请选择${that.state.item.standard_mapping[curr].name}`}]}
                            label={that.state.item.standard_mapping[curr].name}>
                            <UI.Radio.Group onChange={that.SelectNewService} name={curr}>
                               <FormRadio vls={that.state.item.standard_mapping[curr].values}/>
                            </UI.Radio.Group>
                        </UI.Form.Item>
                    )
                })
            )
        }
        if (Object.keys(this.state.item).length === 0) {
            return (
                <div style={{textAlign:"center",background:"#ffffff",marginTop:"3.2rem",padding:"3.2rem"}}>
                    等待产品加载...
                </div>
            )
        }
        var that = this;
        return (
            <div>
                <PageHeader
                    title="产品在线采购"
                    onBack={()=>{
                        that.props.history.push(this.state.item.sys_url)
                    }}
                    subTitle={that.state.item.sub_title}/>
                    <div style={{width: "60%", margin: "26px auto",padding:"2.2rem",background:"#ffffff"}}>
                        <h2>{that.state.item.title}</h2>
                        <small style={{marginBottom:"5.6rem"}}>{that.state.item.sub_title}</small>
                        <UI.Form onFinish={this.Handle} labelCol={{span:24}} labelAlign={"left"}>
                            <Choose/>
                            <Domain/>
                            <UI.Form.Item label="采购数量" name="qty" rules={[{required:true,message:"请输入采购数量"}]} initialValue={this.state.defaultQty}>
                                <UI.InputNumber min={1} max={99999}/>
                            </UI.Form.Item>
                            <Client/>
                            <UI.Form.Item style={{textAlign:'right'}}>
                                <h2 style={{color:"red"}}>{that.state.currVariant.sale_price} </h2>{that.state.item.currency}
                            </UI.Form.Item>
                            {that.state.item.is_saas_service &&
                            <UI.Form.Item label="管理端域名" name="property[adminDomain]" rules={[{required:true,message:"请输入管理端域名"}]}>
                                <UI.Input placeholder={"请输入管理端域名"}/>
                            </UI.Form.Item>
                            }
                            {that.state.item.with_client &&
                            <UI.Form.Item label="客户端域名" name="property[clientDomain]" rules={[{required:true,message:"请输入客户端域名名"}]}>
                                <UI.Input placeholder={"请输入客户端域名"}/>
                            </UI.Form.Item>
                            }
                            <UI.Form.Item style={{textAlign:'right'}}>
                                <UI.Button type="primary" shape="round" htmlType="submit">提交订单</UI.Button>
                            </UI.Form.Item>
                        </UI.Form>
                    </div>
            </div>
        )
    }
}
