/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/9/12
 */
import React, { Component } from 'react'
import * as ui from 'antd'
import apis from '../../../../utils/apis'
import { BellOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout';
import dayjs from 'dayjs'

const { TextArea } = ui.Input
const { Option } = ui.Select

export default class Consumer extends Component {
  constructor() {
    super()
    this.state = {
      tableData: [],
      paging: { total: 0, pageNumber: 1, pageSize: 20 },
      tableLoad: false,
      searchKey: {},
      editShow: false,
      editCurr: {},
      submitting: false,
      audienceChannels: [],
      msgCurr: {},
      msgShow: false,
      msgHandling: false
    }
  }

  componentDidMount() {
    this.audienceChannelSync()
    this.tableDataSync(1)
  }

  /**
   * 加载执行分组
   */
  audienceChannelSync() {
    var param = {}
    param.pageNumber = 1
    param.pageSize = 2000
    var that = this
    apis.messagePushChannelList(param).then(res => {
      that.setState({
        audienceChannels: res.data.data.data
      })
    })
  }

  /**
   * 发起搜索
   * @param params
   * @constructor
   */
  makeSearch = (params) => {
    this.setState({
      searchKey: params
    })
    var that = this
    setTimeout(function() {
      that.tableDataSync()
    }, 200)
  }
  /**
   * 签名列表
   * @param pageNumber
   Object.assign(para, that.state.keywords)
   * @param pageSize
   * @constructor
   */
  tableDataSync = (pageNumber = 1) => {
    let that = this
    let para = {}
    para.pageNumber = pageNumber
    para.pageSize = 20
    that.setState({
      tableLoad: true
    })
    Object.assign(para, that.state.searchKey)
    apis.messagePushAudienceList(para).then(function(resp) {
      that.setState({
        tableLoad: false
      })
      var curr = {
        total: resp.data.data.total,
        pageNumber: pageNumber
      }
      that.setState({
        tableData: resp.data.data.data,
        paging: curr
      })
    })
  }


  render() {
    var that = this

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        title: '受众姓名',
        key: 'id',
        render: function(value, row, index) {
          return row.show_name
        }
      },
      {
        title: '推送平台用户名',
        key: 'id',
        render: function(value, row, index) {
          return row.user_name
        }
      },
      {
        title: '推送渠道',
        key: 'id',
        render: function(value, row, index) {
          return row.push_channel.name
        }
      },
      {
        title: '消息通道',
        key: 'id',
        render: function(value, row, index) {
          return row.push_channel.authorize_channel.name
        }
      },
      {
        title: '创建时间',
        key: 'id',
        render: function(value, row, index) {
          return dayjs(row.created_time).format('YYYY-MM-DD HH:mm:ss')
        }
      },
      {
        title: '可用',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'circle'} size={'small'} type={'primary'} danger={true}>{row.state ? '是' : '否'}</ui.Button>
          )
        }
      },
      {
        title: '管理',
        key: 'id',
        widget: '160',
        align: 'center',
        fixed: 'right',
        render: function(value, row, index) {
          return (
            <span>
               <ui.Button onClick={() => {
                 var data = JSON.parse(JSON.stringify(row))
                 data.push_channel = data.push_channel.id
                 that.setState({
                   editCurr: data,
                   editShow: true
                 })
               }} type={'link'}>编辑</ui.Button>
              <ui.Button onClick={() => {
                var data = JSON.parse(JSON.stringify(row))
                that.setState({
                  msgCurr: data,
                  msgShow: true,
                  msgHandling: false
                })
              }} type={'link'}>发送测试</ui.Button>
            </span>
          )
        }
      }
    ]

    return (
      <div>
        <PageHeader title="授权受众管理" subTitle="针对目标用户群进行许可式授权" extra={[
          <ui.Button key="1" type="primary" shape="round" onClick={() => {
            that.setState({
              editCurr: {},
              editShow: true,
              submitting: false
            })
          }}>新增授权</ui.Button>
        ]} />
        <div style={{ color: 'darkcyan', fontSize: '10px', marginBottom: '16px' }}>
          <BellOutlined /> 免费用户可管理至多2000个许可式授权受众
        </div>
        <ui.Card>
          <div style={{ marginTop: '22px' }}>
            <ui.Form onFinish={that.makeSearch} layout={'inline'}>
              <ui.Form.Item label={'用户名称'} name={'name__contains'}>
                <ui.Input placeholder={'用户名称'} />
              </ui.Form.Item>
              <ui.Form.Item label={'用户显示名称'} name={'show_name__contains'}>
                <ui.Input placeholder={'用户显示名称'} />
              </ui.Form.Item>
              <ui.Form.Item>
                <ui.Button type={'primary'} htmlType={'submit'}>快速搜索</ui.Button>
              </ui.Form.Item>
            </ui.Form>
          </div>
        </ui.Card>

        <div style={{ marginTop: '22px' }}>
          <ui.Table
            rowKey="id"
            dataSource={this.state.tableData}
            columns={columns}
            loading={this.state.tableLoad}
            pagination={{
              total: this.state.paging.total,
              defaultPageSize: 20,
              defaultCurrent: this.state.paging.pageNumber,
              onChange: this.tableDataSync
            }} />
        </div>

        <ui.Modal open={that.state.editShow} title={'目标受众在线管理'} onCancel={() => {
          that.setState({
            editCurr: {},
            editShow: false,
            submitting: false
          })
        }} footer={null} destroyOnClose>
          <ui.Form onFinish={(params => {
            that.setState({
              submitting: true
            })
            if (that.state.editCurr.id) {
              params.id = that.state.editCurr.id
            }
            apis.messagePushAudienceModify(params).then(resp => {
              that.setState({
                submitting: false
              })
              if (resp.data.code === 200) {
                ui.message.success('提交信息成功')
                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                that.setState({
                  submitting: false,
                  editShow: false,
                  editCurr: {}
                })
              } else {
                ui.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
              }
            })
          })} labelCol={{ span: 24 }} labelAlign={'left'} initialValues={that.state.editCurr}>
            <ui.Form.Item label={'目标受众名称'} name={'show_name'}
                          rules={[{ required: true, message: '目标受众名称' }]}>
              <ui.Input placeholder={'目标受众名称'} />
            </ui.Form.Item>
            <ui.Form.Item label={'群组受众'} name={'is_group'}
                          rules={[{ required: true, message: '群组受众' }]}>
              <ui.Radio.Group>
                <ui.Radio value={true}>群组</ui.Radio>
                <ui.Radio value={false}>个人</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            <ui.Form.Item label={'目标受众帐户'} name={'user_name'}
                          rules={[{ required: true, message: '目标受众帐户' }]}>
              <ui.Input placeholder={'目标受众帐户'} />
            </ui.Form.Item>
            <ui.Form.Item label={'目标受众唯一id'} name={'user_unique_id'}
                          rules={[{ required: false, message: '目标受众唯一id' }]}>
              <ui.Input placeholder={'目标受众唯一id'} />
            </ui.Form.Item>
            <ui.Form.Item label={'受众渠道'} name={'push_channel'} rules={[{ required: true, message: '受众渠道' }]}>
              <ui.Select placeholder={'受众渠道'} style={{ width: '100%' }}>
                {that.state.audienceChannels.map(item => {
                  return (
                    <Option value={item.id} key={item.id}>{item.name}</Option>
                  )
                })}
              </ui.Select>
            </ui.Form.Item>
            <ui.Form.Item label={'目标受众状态'} name={'state'}
                          rules={[{ required: true, message: '目标受众状态' }]}>
              <ui.Radio.Group>
                <ui.Radio value={true}>可用</ui.Radio>
                <ui.Radio value={false}>禁用</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            <ui.Form.Item>
              <ui.Button htmlType={'submit'} loading={that.state.submitting} type={'primary'} block>立即提交</ui.Button>
            </ui.Form.Item>
          </ui.Form>
        </ui.Modal>
        <ui.Modal open={that.state.msgShow} onCancel={() => {
          that.setState({
            msgCurr: {},
            msgShow: false,
            msgHandling: false
          })
        }} footer={null} destroyOnClose>
          <h2>受众消息发送测试</h2>
          <small>使用当前通道实时推送信息到用户</small>
          <ui.Divider />
          <ui.Form onFinish={(params => {
            that.setState({
              submitting: true
            })
            if (that.state.msgCurr.id) {
              params.account_id = that.state.msgCurr.id
            }
            apis.messagePushSend(params).then(resp => {
              that.setState({
                submitting: false
              })
              if (resp.data.code === 200) {
                ui.message.success('提交信息成功')
                that.setState({
                  msgCurr: {},
                  msgShow: false,
                  msgHandling: false
                })
              } else {
                ui.message.error(resp.data.msg)
              }
            })
          })} labelCol={{ span: 24 }} labelAlign={'left'} initialValues={that.state.editCurr}>
            <ui.Form.Item label={'消息内容'} name={'msg_type'}
                          rules={[{ required: true, message: '消息内容' }]}>
              <ui.Radio.Group>
                <ui.Radio value={'text'}>文本内容</ui.Radio>
                <ui.Radio value={'video'}>视频连接</ui.Radio>
                <ui.Radio value={'image'}>图片连接</ui.Radio>
                <ui.Radio value={'file'}>文件连接</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            <ui.Form.Item label={'消息内容'} name={'arguments'}
                          rules={[{ required: true, message: '消息内容' }]}>
              <TextArea rows={10} placeholder={'消息内容'} />
            </ui.Form.Item>
            <ui.Form.Item>
              <ui.Button htmlType={'submit'} loading={that.state.submitting} type={'primary'} block>立即推送</ui.Button>
            </ui.Form.Item>
          </ui.Form>
        </ui.Modal>
      </div>
    )
  }
}